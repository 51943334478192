<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-select
            codeGroupCd="REGULATION_BILL_API_CD"
            itemText="codeName"
            itemValue="code"
            label="관련 법규"
            name="searchLaw"
            @datachange="billCdChange"
            v-model="searchLaw">
          </c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
          <c-multi-select
            type="search"
            :comboItems="regulatedItems"
            itemText="regulatedItemName"
            itemValue="regulatedItemCdReplace"
            label="규제 법규"
            name="regulatedItemCd"
            v-model="searchParam.regulatedItemCd">
          </c-multi-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="화학자재 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="openPop" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'chem-manage',
  data() {
    return {
      searchLawItems: [],
      searchLaw: [],
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        mdmChemMaterialId: '',
        materialName: '',
        regulatedItemCd: [],
        searchItems: [],
        headerType: 'color'
      },
      regulatedItems: [],
      originalData: [],
      filterData: [],
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            fix: true,
            id: 'original',
            name: 'plantName',
            field: 'plantName',
            label: '사업장명',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            fix: true,
            id: 'original',
            name: 'materialCd',
            field: 'materialCd',
            label: '자재코드',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            fix: true,
            id: 'original',
            name: 'materialName',
            field: 'materialName',
            label: '화학자재명',
            align: 'left',
            type: 'link',
            style: 'width:200px',
            sortable: true,
          },
          {
            id: 'original',
            name: 'propertiesStateName',
            field: 'propertiesStateName',
            label: '성상',
            align: 'center',
            style: 'width:60px',
            sortable: true,
          },
          {
            name: 'inputDissolutionTank',
            field: 'inputDissolutionTank',
            label: '투입<br/>용해조',
            align: 'center',
            style: 'width:75px',
            sortable: true,
          },
          {
            id: 'original',
            name: 'usageName',
            field: 'usageName',
            label: '용도',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          }
        ],
        data: [],
      },
      editable: true,
      headerUrl: '',
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.headerUrl = selectConfig.mdm.mam.chem.header.url;
    this.getHeader();
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.mdm.mam.chem.listreg.url;
      this.regsUrl = selectConfig.mdm.mam.chem.regs.url;
      this.getList();
    },
    getHeader() {
      this.$http.url = this.headerUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$_.forEach(_result.data, _item => {
          this.grid.columns.push(_item);
        })
        this.originalData = this.$_.cloneDeep(this.grid.columns);
      },);
    },
    billCdChange(newValue) {
      this.searchLawItems = [];
      this.searchParam.regulatedItemCd = [];
      if (newValue.length === 0) {
        this.grid.columns = this.$_.cloneDeep(this.originalData);
      } else {
        this.$_.forEach(newValue, _item => {
          this.searchLawItems.push(_item.value);
        })
        var _searchLawItems = this.searchLawItems;
        this.filterData = this.originalData.filter(function (object) {
          return _searchLawItems.indexOf(object['id']) > -1 || object['id'] === 'original';
        });
        this.grid.columns = this.filterData;
      }
      this.searchParam.searchItems = [];
      this.$_.forEach(newValue, _item => {
        this.searchParam.searchItems.push(_item.value)
      })
      this.$http.url = this.regsUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.regulatedItems = _result.data;
      },);
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '화학자재 상세';
      this.popupOptions.param = {
        mdmChemMaterialId: row ? row.mdmChemMaterialId : '',
        plantCd: row ? row.plantCd : '',
      };
      this.popupOptions.target = () => import(`${'./chemDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
